import React from 'react'

function Ecosection() {
  return (
     <div className='position-relative eco-section my-3'>
          <div className='container position-absolute top-50 start-50 translate-middle'>
               <div>
                    <h1 className='text-center pb-4'>
                         <span className='fw-bold text-white'>Cards made with eco-friendly materials. Fully </span>
                         <span className='fw-bold'>Customisable.</span>
                    </h1>
               </div>
          </div>
     </div>
  )
}

export default Ecosection