import React from 'react'
import "./Navbar.css";
// import Logolight from '../../../images/logo-light.webp'
import Logodark from '../../../images/logofull-dark.webp'


function Navbar() {
  return (
    <div className='navBar fixed-top'>
      <nav className='navbar navbar-dark navbar-expand-lg bg-black'>
        <div className="container-fluid container">
          <a className="navbar-brand" href="#home">
            <img src={Logodark} alt="ISCE Logo Light" height="25" className="d-inline-block align-text-top"/>
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse text-center" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="mx-4 my-3 nav-item">
                <a className="nav-link active" aria-current="page" href="#card">CARD</a>
              </li>
              <li className="mx-4 my-3 nav-item">
                <a className="nav-link active" aria-current="page" href="#about">ABOUT</a>
              </li>
              <li className="mx-4 my-3 nav-item d-sm-none d-block">
                <a href='#footer-preorder' type="button" className="btn btn-dark agrad">Pre-order Now</a>
              </li>
            </ul>
          </div>
          <div className="d-md-flex d-none gap-3">
            <a href='#footer-preorder' type="button" className="btn btn-dark agrad">Pre-order Now</a>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar