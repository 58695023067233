import React from 'react'

function Watchsection() {
  return (
     <section id="intro" className='h-screen position-relative bg-dark overflow-hidden'>
          <div className='mask h-100'>
               <div className="shadow-2-strong">
                    <div className="container position-absolute top-50 start-50 translate-middle faqss">
                         <div className='text-white'>
                              <h1>Your Security is our Priority</h1>
                         </div>
                    </div>
               </div>
          </div>
     </section>
  )
}

export default Watchsection;