import React from 'react'
import "./index.css"
import Navbar from "./Navbar/Navbar";
import Earzsection from './Earzsection/Earzsection';
// import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Cardsection from './Cardsection/Cardsection';
import Watchsection from './Watchsection/Watchsection';
import Ecosection from './Ecosection/Ecosection';

function Index() {
  return (
    <div>
     <Navbar />
     <Earzsection />
     {/* <Header /> */}
     <Cardsection />
     <Watchsection />
     <Ecosection />
     <Footer />
    </div>
  )
}

export default Index