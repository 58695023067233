import React from 'react'
import cardlight from '../../../images/cardlight.webp'
import carddark from '../../../images/carddark.webp'

function Cardsection() {
  return (
     <section className='h-screen position-relative bg-dark overflow-hidden' id='about'>
          <div className='container position-absolute top-50 start-50 translate-middle'>
               <div>
                    <h1 className='text-center text-light pb-5 mb-4'>
                         <span className='fw-bold'>Store and Access Your</span><br/>
                         <span className='header-text agrad-color'>BUSINESS CARD | VOTERS CARD | PAYMENT CARDS</span>
                    </h1>
               </div>
               <div className='row mx-auto conbg-circle position-relative'>
                    <div className='cl-rel'>
                         <img src={cardlight} alt="ISCE Card Light" height="200" className="d-inline-block align-text-top"/>
                    </div>
                    <div className='cr-rel'>
                         <img src={carddark} alt="ISCE Card Dark" height="200" className="d-inline-block align-text-top"/>
                    </div>
               </div>
                    <div className="pt-5 text-center">
                         <a href='#footer-preorder' type="button" className="btn btn-dark agrad">Pre-order Now</a>
                    </div>
          </div>
     </section>
  )
}

export default Cardsection