import React from 'react'
// import Contact from './pages/user/contact';
// import Swipe from './components/swipe/swipe.component';
import Preorder from './pages/preorder'
// import Connect from './pages/user/connect';

function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      {/* <Login /> */}
      {/* <Signup /> */}
      {/* <Password /> */}
      {/* <Personal /> */}
      {/* <Code /> */}
      {/* <Socials /> */}
      {/* <Profile /> */}
      {/* <Swipe /> */}
      {/* <Contact /> */}
      <Preorder />
      {/* <Connect /> */}
    </div>
  );
}

export default App;
