import React, { Component } from 'react'
import * as yup from 'yup';
import axios from 'axios';

class Footer extends Component {
  constructor(props){
    super(props);
    this.state = initialState
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.updateUserInput = this.updateUserInput.bind(this);
  }

  reset(){
    this.setState(initialState);
  }

  updateUserInput(e){
    e.preventDefault();
    let that = this;
    const updateObj = e.target.getAttribute('name');
    this.setState({
      ...that.state,
      [updateObj]: e.target.value
    });
  }

  handleFormSubmit(e){
    e.preventDefault();
    // check validity
    const that = this;

    that.setState({
      ...this.state,
      submitting: true,
      alert_val: '',
      alert_color: 'danger'
    })

    schema
    .isValid(that.state)
    .then(function (valid) {
      if(valid){
        const { name, email, phone } = that.state;
        const data = { name, email, phone };
        const that_this = that;
        axios.post(that.state.host + '/preorder/public/save', data)
        .then(function (response) {
          that_this.setState({
            ...that_this.state,
            submitting: false,
            alert_val: response.message,
            alert_color: 'danger'
          })
          //that_this.reset();
        })
      }
    })

  }

  renderAlert(){
    if(!this.state.alert_val) return '';
    return (
      <div className={ `alert alert-${this.state.alert_color}` } role="alert">
        { this.state.alert_val }
      </div>
    );
  }

  render(){
    return (
      <div>
        <footer className='footer bg-black' id='footer-preorder'>
          <div className="container">
            <div className="row align-items-center py-5">
              <div className="my-2 col-sm text-light text-center text-lg-start">
                <p>
                  About ISCE Cards
                </p>
                <p>
                  Get a smoother way to network with just one tap. Not limited to business owners and events,
                  you can freely vibe and share your details with anyone, anywhere, turning handshakes into
                  meaningful connections.
                </p>
              </div>
              <div className="my-2 col-sm text-light text-start">
                <form className='pb-3'>
                  <h4 className="text-center text-lg-center">
                    <b>Pre-Order Now</b>
                  </h4>
                  { (this.state.alert_val) ? (<div className={ `alert alert-${this.state.alert_color}` } role="alert">
                          { this.state.alert_val }
                  </div>) : '' }
                  <div className='mb-2'>
                    <input name="name" className={ `bg-light form-control` } type='text' placeholder='TYPE YOUR NAME' value={ this.state.name } onInput={ this.updateUserInput } />
                  </div>
                  <div className='mb-2'>
                    <input name="phone" className={ `bg-light form-control` } type='text' placeholder='TYPE YOUR PHONE NUMBER' value={ this.state.phone } onInput={ this.updateUserInput } />
                  </div>
                  <div className="input-group flex-nowrap">
                    <input name="email" type="text" className={ `bg-light form-control` } placeholder="EMAIL ADDRESS" value={ this.state.email } aria-label="email" aria-describedby="addon-wrapping" onInput={ this.updateUserInput } />
                    <button className="input-group-text bg-black text-light" id="addon-wrapping" onClick={ this.handleFormSubmit }>{ (this.state.submitting) ? 'SENDING...' : 'SEND' } </button>
                  </div>
                </form>
                <p>
                  Floor 3, Polystar Building, Marwa, Lekki, Lagos<br/>
                  preorder@isce.app<br/>
                  +234-812-433-9827
                </p>
              </div>
              <div className="my-2 col-sm text-light text-center text-lg-end">
                <p> 
                  Scan the card then log in to connect your profile to your card.
                  If you are a customer with no card or profile you can go digital with 3 simple steps:<br/><br/>
                  Scan your card with your mobile phone.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}


const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/im

let schema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter your phone number").typeError("Enter a valid phone number"),
  email: yup.string().email("Your email is not valid").required("Please enter your email").typeError("Enter a valid email")
});

const initialState = {
  name: '',
  phone: '',
  email: '',
  submitting: false,
  alert_val: '',
  alert_color: 'danger',
  host: 'https://isce.app/developer'
}

export default Footer;